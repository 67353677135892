.App {
  display: flex;
  flex-direction: column;
  color: white;
  background-color: #0b0c10;
}

.Header {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  background-image: url(./img/StarField.png);
  background-color: #000;
  min-height: 30vh;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-bottom: 30px;
}

h2 {
  font-size: 2.6rem;
}

.Nav {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  padding-top: 0;
  background-color: #1f2833;
}

.Definition {
  display: flex;
  padding-bottom: 30px;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #1f2833;
}

.Info {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #1f2833;
  padding-bottom: 25px;
}

.WorldsPreview {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #1f2833;
}

.Controls {
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
}

.Quotes {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #c5c6c7;
  color: #1f2833;
  min-height: 30vh;
}

.Footer {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  background-color: #45a29e;
}

.ClaimButton {
  background-color: #1f2833;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 20px 0px;
  cursor: pointer;
  border-style: solid;
  border-color: white;
}

.ClaimButton:active {
  background-color: #45a29e;
  color: #1f2833;
  border-color: #1f2833;
}

.ConnectButton {
  background-color: #1f2833;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 20px 0px;
  cursor: pointer;
  border-style: solid;
  border-color: white;
}

.Connectutton:active {
  background-color: #45a29e;
  color: #1f2833;
  border-color: #1f2833;
}

.NavButton {
  background-color: #1f2833;
  color: white;
  font-size: 20px;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  border-style: solid;
  border-color: white;
  display: inline-block;
}

.NavButton:active {
  background-color: #45a29e;
  color: #1f2833;
  border-color: #1f2833;
}

.Warning {
  color: red;
  font-weight: bold;
  padding-bottom: 20px;
}

@media (hover: hover) and (pointer: fine) {
  .ClaimButton:hover {
    background-color: #c5c6c7;
    color: #1f2833;
    border-color: #0b0c10;
  }

  .NavButton:hover {
    background-color: #c5c6c7;
    color: #1f2833;
    border-color: #0b0c10;
  }
}

input {
  border: 2px solid #66fcf1;
  outline: none;
  box-shadow: none;
  border-radius: 5px;
  background-color: #1f2833;
  color: white;
  margin-inline: 10px;
  font-size: 1rem;
  width: 80px;
  text-align: center;
}

body {
  padding: 0;
  margin: 0;
}

p {
  margin: 0px;
}

.fade-in {
  transition: opacity 1s ease;
}
.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

@function sqrt($r) {
  $x0: 1;
  $x1: $x0;

  @for $i from 1 through 10 {
    $x1: $x0 - calc(($x0 * $x0 - abs($r)) / (2 * $x0));
    $x0: $x1;
  }

  @return $x1;
}

.claimedBadge {
  $width: 140px;
  $height: 24px;
  $containerSide: $width;
  $newOrigin: calc(($width - $height) / (2 * sqrt(2)));
  $rotateTL: -45deg;
  $rotateTR: 45deg;

  position: absolute;
  width: $containerSide;
  height: $containerSide;
  overflow: hidden;

  &::before {
    content: 'Claimed';
    background: red;
    color: #fff;
    font-weight: bold;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    display: block;
    position: relative;
    width: $width;
    height: $height;
    line-height: $height;
    text-align: center;
    cursor: default;
  }

  &.badge-top-right {
    top: 0;
    right: 0;

    &::before {
      top: $newOrigin - calc($height / 2);
      right: $newOrigin - calc($width / 2);
      -webkit-transform: rotate($rotateTR);
      -moz-transform: rotate($rotateTR);
      -ms-transform: rotate($rotateTR);
      -o-transform: rotate($rotateTR);
      transform: rotate($rotateTR);
    }
  }

  &.badge-top-left {
    top: 0;
    left: 0;

    &:before {
      top: $newOrigin - calc($height / 2);
      left: $newOrigin - calc($width / 2);
      -webkit-transform: rotate($rotateTL);
      -moz-transform: rotate($rotateTL);
      -ms-transform: rotate($rotateTL);
      -o-transform: rotate($rotateTL);
      transform: rotate($rotateTL);
    }
  }
}

.unclaimedBadge {
  $width: 140px;
  $height: 24px;
  $containerSide: $width;
  $newOrigin: calc(($width - $height) / (2 * sqrt(2)));
  $rotateTL: -45deg;
  $rotateTR: 45deg;

  position: absolute;
  width: $containerSide;
  height: $containerSide;
  overflow: hidden;

  &::before {
    content: 'Available';
    background: #9c8ff2;
    color: #fff;
    font-weight: bold;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    display: block;
    position: relative;
    width: $width;
    height: $height;
    line-height: $height;
    text-align: center;
    cursor: default;
  }

  &.badge-top-right {
    top: 0;
    right: 0;

    &::before {
      top: $newOrigin - calc($height / 2);
      right: $newOrigin - calc($width / 2);
      -webkit-transform: rotate($rotateTR);
      -moz-transform: rotate($rotateTR);
      -ms-transform: rotate($rotateTR);
      -o-transform: rotate($rotateTR);
      transform: rotate($rotateTR);
    }
  }

  &.badge-top-left {
    top: 0;
    left: 0;

    &:before {
      top: $newOrigin - calc($height / 2);
      left: $newOrigin - calc($width / 2);
      -webkit-transform: rotate($rotateTL);
      -moz-transform: rotate($rotateTL);
      -ms-transform: rotate($rotateTL);
      -o-transform: rotate($rotateTL);
      transform: rotate($rotateTL);
    }
  }
}

/* Extra small devices (phones, 640px and down) */
@media only screen and (max-width: 640px) {
  .WorldsImage {
    width: 84vw;
    height: auto;
    border-radius: 10px;
  }

  .TextBlock {
    width: 86vw;
    font-size: 1.2rem;
  }

  .QuoteBlock {
    width: 86vw;
    font-size: 1.3rem;
  }
}

/* Small devices (portrait tablets and large phones, 640px and up) */
@media only screen and (min-width: 640px) {
  .WorldsImage {
    width: 60vw;
    height: auto;
    background-color: black;
    border-radius: 10px;
  }

  .TextBlock {
    width: 76vw;
    font-size: 1.2rem;
  }

  .QuoteBlock {
    width: 76vw;
    font-size: 1.3rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .WorldsImage {
    width: 44vw;
    height: auto;
    background-color: black;
    border-radius: 10px;
  }

  .TextBlock {
    width: 68vw;
    font-size: 1.2rem;
  }

  .QuoteBlock {
    width: 68vw;
    font-size: 1.3rem;
  }
}

/* Large devices (laptops/desktops, 1024px and up) */
@media only screen and (min-width: 1024px) {
  .WorldsImage {
    width: 34vw;
    height: auto;
    background-color: black;
    border-radius: 10px;
  }

  .TextBlock {
    width: 56vw;
    font-size: 1.2rem;
  }

  .QuoteBlock {
    width: 56vw;
    font-size: 1.3rem;
  }
}

/* Large devices (large laptops and desktops, 1280px and up) */
@media only screen and (min-width: 1280px) {
  .WorldsImage {
    width: 30vw;
    height: auto;
    background-color: black;
    border-radius: 10px;
  }

  .TextBlock {
    width: 48vw;
    font-size: 1.2rem;
  }

  .QuoteBlock {
    width: 48vw;
    font-size: 1.3rem;
  }
}

/* Extra large devices (large laptops and desktops, 1536px and up) */
@media only screen and (min-width: 1536px) {
  .WorldsImage {
    width: 22vw;
    height: auto;
    background-color: black;
    border-radius: 10px;
  }

  .TextBlock {
    width: 44vw;
    font-size: 1.2rem;
  }

  .QuoteBlock {
    width: 44vw;
    font-size: 1.3rem;
  }
}
